import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const state = () => ({
    offer: null,
    payLink: null,
    loadingOffer: true,
    loadingOfferError: false,
    loadingSignOffer: false,
    errorOffer: false,
    errorSignOffer: false,
    errorPayOffer: false,
    loadingPayOffer: false,
});
const actions = {
    /**
     *
     * Get offer
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async getOffer({commit}, data) {
        try {
            await commit('offerDataBegin');
            const response = await DataService.get(`/${data.tenant}/offer/${data.token}`);
            await commit('offerDataSuccess', response.data);
        } catch (err) {
            await commit('offerDataErr', err);
            addNotificationError(err.response.data.message);
        }
    },
    /**
     *
     * Create offer
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async signOffer({commit, dispatch}, data) {
        try {
            await commit('signOfferDataCreateBegin');
            await DataService.post(`/${data.tenant}/offer/${data.token}/sign`, data);
            await dispatch('getOffer' , data)
            await commit('signOfferDataCreateSuccess');
        } catch (err) {
            await commit('signOfferDataCreateErr', err);
            addNotificationError(err.response.data.message);
        }
    },
    async generatePayLink({commit}, data) {
        try {
            await commit('payOfferDataCreateBegin');
            const response = await DataService.post(`/${data.tenant}/offer/${data.token}/pay`, data);
           data.openLink(response.data.url??'')
            await commit('payOfferDataCreateSuccess');
        } catch (err) {
            await commit('payOfferDataCreateErr', err);
            addNotificationError(err.response.data.message);
        }
    },
    async cleanOffer({commit}) {
        commit('cleanOffer')
    }
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
